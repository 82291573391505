import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
function Login({ verify,setuser,setpasswd }) {
    return (
        <div className='login'>
            <form>
                <h3>Sign In</h3>
                <div className="mb-3">
                    <label>Username</label>
                    <input
                        type="Text"
                        className="form-control"
                        placeholder="Enter username"
                        onChange={(e) => {
                            setuser(e.target.value);
                        }}
                    />
                </div>
                <div className="mb-3">
                    <label>Password</label>
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        onChange={(e) => {
                            setpasswd(e.target.value);
                        }}
                    />
                </div>
                {/* <div className="mb-3">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                        />
                        <label className="custom-control-label" htmlFor="customCheck1">
                            Remember me
                        </label>
                    </div>
                </div> */}
                <div className="d-grid">
                    <button type="button" className="btn btn-primary"
                        onClick={verify}
                    >
                        Submit
                    </button>
                </div>
                {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}
            </form>
        </div>
    );
}

export default Login;