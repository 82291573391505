import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Stepper from "react-stepper-horizontal";

//import Placeholder from "react-bootstrap/Placeholder";

const Footer = () => {
  return (
    <Card
      key={uuidv4()}
      style={{ width: "100%", borderColor: "#313131", border: 0, height: 50 }}
    ></Card>
  );
};

function join(date, options, separator) {
  function format(option) {
    let formatter = new Intl.DateTimeFormat("th", option);
    return formatter.format(date);
  }
  return options.map(format).join(separator);
}
const Docnumber = ({ props }) => {
  const { wsno, wsstatus, wstimestamp, wsdocuments, wsmessage, wsdata } = props;
  const options = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var ns_doc_number = "N/A";
  var _status_ = "success";
  var _st_ = "S";
  //  var _date_ = join(new Date(wstimestamp), options, "-");
  if (wsmessage) {
    if (wsmessage._v.status !== "success") {
      _status_ = "warning";
      _st_ = "N";
    } else {

      ns_doc_number = wsmessage._v.data.ns_doc_number ? wsmessage._v.data.ns_doc_number : wsmessage._v.data.ns_internal_id;
    }
  }
  else {
    if (wsstatus !== "success") {
      _status_ = "warning";
      _st_ = "N";
    }
  }
  //console.log("Height =:" + _hehght_);
  return (
    <div style={{ width: "100%", borderColor: "#313131", margin: 1 }} hidden={wsdata ? wsdata._v.is_spc : false}>
      <ListGroup as="ol">
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start "
          active
          onClick={handleShow}
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{wsno}</div>
          </div>
          <Badge bg={_status_} pill>
            {_st_}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item as="li" className={wsstatus}>
          {wsmessage ?
            wsstatus === "success" ?
              <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns: <b>{ns_doc_number}</b></div>
                <div style={{ fontSize: 16 }} >
                  ทะเบียนรถ: <b>{wsdata
                    ? wsdata._v.ns_car_registration ? wsdata._v.ns_car_registration : wsdata._v.Register_Car
                    : wsdocuments._v.ns_car_registration}</b></div>
              </> : wsmessage._v.status === "success" ? <><div>
                หมายเลขจาก ns: <b>{wsmessage._v.data.ns_doc_number}</b></div>
                <div style={{ fontSize: 16 }} >
                  ทะเบียนรถ: <b>{wsdata
                    ? wsdata._v.ns_car_registration ? wsdata._v.ns_car_registration : wsdata._v.Register_Car
                    : wsdocuments._v.ns_car_registration}</b></div>
              </> : <>
                <div style={{ fontSize: 16 }}>
                  <b>{wsmessage._v.message}</b></div>
              </>
            : <div>
              {wsno.indexOf("LP") !== -1 ? <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns : <b>{wsdocuments._v.ns_lp_internal_id}</b></div>
                <div style={{ fontSize: 16 }} >
                  Date sync : <b>{wsdocuments._v.ns_lp_date_time_sync}</b></div>
              </> : wsno.indexOf("FP") !== -1 ? <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns : <b>{wsdocuments._v.ns_fp_internal_id}</b></div>
                <div style={{ fontSize: 16 }} >
                  Date sync : <b>{wsdocuments._v.ns_fp_date_time_sync}</b></div>
              </> : wsno.indexOf("WO") !== -1 ? <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns : <b>{wsdata ? wsdata._v ? wsdata._v.Register_Car : "" : ""}</b></div>
                <div style={{ fontSize: 16 }} >
                  item : <b>{wsdata ? wsdata._v ? wsdata._v.goods_name : "" : ""}</b></div>
              </> : wsno.indexOf("TR") !== -1 ? wsdata._v ? <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns : <b>{wsdata._v.ns_car_registration}</b></div>
                <div style={{ fontSize: 16 }} >
                  item : <b>{wsdata._v.goods_Name}</b></div>
              </> : wsno.indexOf("IF") !== -1 ? wsdocuments._v ? <>
                <div style={{ fontSize: 16 }} >
                  item : <b>{wsdocuments._v.item[0].ns_internal_id_item}</b></div>
                <div style={{ fontSize: 16 }} >
                  ทะเบียนรถ : <b>{wsdocuments._v.ns_car_registration}</b></div>
              </> : wsno.indexOf("LG") !== -1 ? <>
                <div style={{ fontSize: 16 }} >
                  หมายเลขจาก ns : <b>{wsdocuments._v.ns_lg_internal_id}</b></div>
                <div style={{ fontSize: 16 }} >
                  Date sync : <b>{wsdocuments._v.ns_lg_date_time_sync}</b></div>
              </> : "N/A" : "N/A" : "N/A"}
            </div>
          }
        </ListGroup.Item>
      </ListGroup>
      <Modal show={show} fullscreen={fullscreen} onHide={handleClose} animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton
          style={{ backgroundColor: "red", color: "white" }}
        >
          <Modal.Title>{wsno}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre><code>{JSON.stringify(wsdocuments ? wsdocuments._v : wsdata._v, null, 2)}</code></pre>
          {/* <textarea key={uuidv4()} cols={60} rows={20} style={{ fontSize: 12, width: "100%", height: "100%" }} value={JSON.stringify(wsdocuments ? wsdocuments._v : wsdata._v, null, "\t")} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function Home({ props }) {
  const { apiver, code, message, data } = props;
  const [items, setItem] = useState([]);
  useEffect(() => {
    //console.log(apiver);
    setItem(data);
  }, [data])
  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div id="features">
            <Row>
              {items.map((d) => {
                //console.log("TEST TIME");
                // console.log(data.wsno);
                return (
                  <Col key={uuidv4()} lg={3}>
                    {/* <Stepper steps={ [{title: 'Step One'}, {title: 'Step Two'}, {title: 'Step Three'}, {title: 'Step Four'}] } activeStep={ 3 } /> */}
                    <Docnumber key={uuidv4()} props={d} />
                    {/* <Boxx key={uuidv4()} text={d} kpi={2}/> */}
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
      <div id="pricing">
        <Row>
          <Col>
            <Footer
              key={uuidv4()}
              props={{
                name: "Price",
                detail:
                  "Some quick example text to build on the card title and make up the bulk of the card s content",
                coverimage: "https://www.melivecode.com/attractions/1.jpg",
                height: 200
              }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Home;
