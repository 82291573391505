import { useEffect, useState } from "react";
import Login from "./components/Login";
import Mnavbar from "./components/Mnavbar";
import Home from "./components/Nsdatalogs";
import cnfs from "./env/cnf.json";

const App = () => {
  const [username, setUsername] = useState(null);
  const [password, setPasswd] = useState(null);
  const [filters, setFiller] = useState([]);
  const base64 = require("base-64");
  const basic_username = "user3564";
  const basic_password = "1qaz@WSX";
  const [token, setToken] = useState(base64.encode(basic_username + ":" + basic_password));
  const [login, setLogin] = useState(false);
  //const [_url_,setUrl] = useState("http://203.151.136.14:7880/api/v1/logs");
  const [database_point, setDatabasePoint] = useState('CKR');
  const [items, setItem] = useState([]);
  const [datas, setDatas] = useState([]);
  const [config, setConfig] = useState(cnfs);
  const [show, setShow] = useState(false);
  const handleupdate = () => setShow(prevState => ({
    check: !prevState.check
  }));
  const _username_ = (u) => { setUsername(u); }
  const _password_ = (p) => { setPasswd(p); }
  const verify_pass = () => {
    const base64 = require("base-64");
    // const username = "user3564";
    // const password = "1qaz@WSX";

    //console.log({ username, passwd });
    fetch("http://ckr.siamscale.com:7880/api/v2/login", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64.encode(username + ":" + password),
      },
      body: JSON.stringify({ username: username, password: password })
    })
      .then((res) => {
        //console.log(config);
        if (res.ok) {
          //setShow(true);
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        //console.log(data);
        setLogin(data.status == "Success" ? true : false);
      })
      .catch((error) => {
        console.log(error);
        //setShow(false);
      });
  }


  const onselect = (e) => {
    setDatabasePoint(e.target.value);
    e.value = e.target.value;
  };
  const queryParameters = new URLSearchParams(window.location.search);
  //var database_point = queryParameters.get('b');
  var _url_ = "http://203.151.136.14:7880/api/v1/logs";
  //console.log(database_point);
  const filtered = config.filter(cnf => {
    return cnf.name === database_point;
  });
  if (filtered.length !== 0) {
    //console.log(filtered);
    _url_ = filtered[0].url_title;
  }
  useEffect(() => {
    //console.log(database_point);
    setConfig(cnfs);
    // call api get dataowner
    fetch(_url_, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token
      }
    })
      .then((res) => {
        //console.log(_url_ + "/list/database_point/" + database_point);
        if (res.ok) {
          setShow(true);
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        setItem(data);
      })
      .catch((error) => {
        setShow(false);
      });
    // call api get dataslogs
    fetch(_url_ + "/list/database_point/" + database_point, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token
      }
    })
      .then((res) => {
        //console.log(_url_ + "/list/database_point/" + database_point);
        if (res.ok) {
          setShow(true);
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        setShow(true);
        //console.log(_url_ + "/list/database_point/" + database_point);
        //console.log(_url_);
        //console.log(data);
        setDatas(data);
        setFiller(data);
      })
      .catch((error) => {
        setShow(false);
      });
    // const timeoutId = setTimeout(() => {
    //   //console.log("Hello, World!");
    // }, 2000);
    //return () => clearTimeout(timeoutId);
  }, [show, database_point, login]);

  const filterHeroes = (e) => {
    //console.log(e.target.value)
    // ค้นหาโดยเลขที่ใบชั่ง
    let result = datas.data.filter((data) => {
      return data.wsno.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
    });
    // ค้นหาโดยสถานะ
    if (result.length === 0) {
      result = datas.data.filter((data) => {
        return data.wsstatus.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      });
    }
    // ค้นหาโดยทะเบียนรถ
    if (result.length === 0) {
      result = datas.data.filter((data) => {
        return data.wsdata ?
          data.wsdata._v ?
            data.wsdata._v.ns_car_registration ?
              data.wsdata._v.ns_car_registration.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 :
              null :
            null :
          null
      });
    }
    //ค้นหาโดยลูกค้า
    if (result.length === 0) {
      result = datas.data.filter((data) => {
        return data.wsdata ?
          data.wsdata._v ?
            data.wsdata._v.ws_customer_name ? data.wsdata._v.ws_customer_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 :
              null :
            null :
          null
      });
    }
    if (result) {
      setFiller({ apiver: "2.0.0", code: 200, message: "success", data: result });
    }
    //console.log(result);
  }
  return (
    <div>
      {!login ? <Login verify={verify_pass} setuser={_username_} setpasswd={_password_} /> : null}
      {login ? <Mnavbar props={items} onclick={handleupdate} sites={config} _select_={onselect} database_point={database_point} onChange={filterHeroes} /> : null}
      {login ? filters.length !== 0 ? <Home props={filters} /> : null : null}
    </div>
  );
}
export default App;